<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo mt-4 mb-4">
          <img
            :src="style.loginImage"
            width="150px"
          >
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('Crie sua senha') }}!
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-slot="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- password -->
            <b-form-group
              label-for="password"
              label="Senha"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Senha')"
                vid="Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    :placeholder="$t('Senha')"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="passConfirmed"
              label="Confirmar senha"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Confirmar senha')"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="passConfirmed"
                    v-model="passConfirmed"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="confirmed-password"
                    :placeholder="$t('Confirmar Senha')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || busy"
              @click="validationForm"
            >
              <b-spinner
                v-if="busy"
                small
                style="margin-bottom: 3px"
              />
              {{ $t('Enviar') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { acl } from '@/mixins'
import { required, min, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BSV
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, acl],
  data() {
    return {
      passConfirmed: '',
      password: '',
      status: '',
      // validation rules
      required,
      confirmed,
      min,
      busy: false,
      token: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    useJwt.logout()

    if (this.$router.currentRoute.query) {
      this.token = this.$router.currentRoute.query.token
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        const usuario = {
          dadoUsuario: {
            senha: this.password,
            token: this.token,
          },
        }
        if (success) {
          const options = {
            url: '/wayhub/v1/usuario/alterarSenha',
            method: 'POST',
            data: JSON.stringify(usuario),
          }
          this.$http(options)
            .then(() => {
              this.showSuccess({
                message: this.$t('Senha alterada com sucesso!'),
              })
              this.$router.push({ name: 'auth-login' })
            })
            .catch(error => {
              this.showError({ error })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
