var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c("b-link", { staticClass: "brand-logo mt-4 mb-4" }, [
              _c("img", {
                attrs: { src: _vm.style.loginImage, width: "150px" },
              }),
            ]),
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("Crie sua senha")) + "! "),
            ]),
            _c("validation-observer", {
              ref: "loginForm",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var invalid = ref.invalid
                    return [
                      _c(
                        "b-form",
                        {
                          staticClass: "auth-login-form mt-2",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-for": "password",
                                label: "Senha",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: _vm.$t("Senha"),
                                  vid: "Password",
                                  rules: "required|min:8",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "input-group-merge",
                                              class:
                                                errors.length > 0
                                                  ? "is-invalid"
                                                  : null,
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "form-control-merge",
                                                attrs: {
                                                  id: "password",
                                                  type: _vm.passwordFieldType,
                                                  state:
                                                    errors.length > 0
                                                      ? false
                                                      : null,
                                                  name: "password",
                                                  placeholder: _vm.$t("Senha"),
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function ($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                { attrs: { "is-text": "" } },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    attrs: {
                                                      icon: _vm.passwordToggleIcon,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.togglePasswordVisibility,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-for": "passConfirmed",
                                label: "Confirmar senha",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: _vm.$t("Confirmar senha"),
                                  rules: "required|confirmed:Password",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "input-group-merge",
                                              class:
                                                errors.length > 0
                                                  ? "is-invalid"
                                                  : null,
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "form-control-merge",
                                                attrs: {
                                                  id: "passConfirmed",
                                                  type: _vm.passwordFieldType,
                                                  state:
                                                    errors.length > 0
                                                      ? false
                                                      : null,
                                                  name: "confirmed-password",
                                                  placeholder:
                                                    _vm.$t("Confirmar Senha"),
                                                },
                                                model: {
                                                  value: _vm.passConfirmed,
                                                  callback: function ($$v) {
                                                    _vm.passConfirmed = $$v
                                                  },
                                                  expression: "passConfirmed",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                { attrs: { "is-text": "" } },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    attrs: {
                                                      icon: _vm.passwordToggleIcon,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.togglePasswordVisibility,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary",
                                type: "submit",
                                block: "",
                                disabled: invalid || _vm.busy,
                              },
                              on: { click: _vm.validationForm },
                            },
                            [
                              _vm.busy
                                ? _c("b-spinner", {
                                    staticStyle: { "margin-bottom": "3px" },
                                    attrs: { small: "" },
                                  })
                                : _vm._e(),
                              _vm._v(" " + _vm._s(_vm.$t("Enviar")) + " "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }